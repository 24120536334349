import { useCallback } from 'react'
import { motion } from 'framer-motion'
import Particles from 'react-particles'
import { Engine, ISourceOptions } from 'tsparticles-engine'
import { loadSlim } from 'tsparticles-slim'

export default function Component() {
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadSlim(engine)
  }, [])

  const particlesOptions: ISourceOptions = {
    background: {
      color: {
        value: "transparent",
      },
    },
    fpsLimit: 120,
    interactivity: {
      events: {
        onHover: {
          enable: true,
          mode: "repulse",
        },
      },
      modes: {
        repulse: {
          distance: 100,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: "#ffffff",
      },
      links: {
        color: "#ffffff",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      move: {
        direction: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: false,
        speed: 2,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 80,
      },
      opacity: {
        value: 0.5,
      },
      shape: {
        type: "circle",
      },
      size: {
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
  }

  const sections = [
    { id: 'about', title: 'who we are', content: 'just two dumb slavs doing stoopid projects to learn and enhance the universe with our terrifyingly magnificent brains.' },
    { id: 'services', title: 'projects and sh.t', content: 'cyberfucking crypto market intelligence, getting rekt, messing with machine learning and automation, launching extremely successful rugpulls as we believe mutual trust is the key' },
    { id: 'contact', title: 'hit us up', content: "if you're craving getting rekt and maybe also consulting your web3 project, shoot us a message. if you're getting any fishy ideas of pulling any rug, kindly fuck off, appreciate it broski." },
  ]
  //     { id: 'projects', title: 'Featured Projects', content: 'Explore our portfolio of groundbreaking blockchain projects, pushing the boundaries of what\'s possible in the crypto space.' },

  return (
      <div className="min-h-screen bg-[#0D1117] text-white font-['Roboto',sans-serif] relative overflow-hidden">
        <Particles
            id="tsparticles"
            init={particlesInit}
            options={particlesOptions}
            className="absolute inset-0 z-0"
        />
        <header className="p-6 flex justify-between items-center relative z-10">
          <motion.h1
              className="text-3xl font-light tracking-wider"
              style={{
                background: "linear-gradient(45deg, #00CFFF, #00FF85, #9D00FF)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              whileHover={{
                scale: 1.05,
              }}
              onHoverStart={(e) => {
                if (e.target) {
                  (e.target as HTMLElement).style.textShadow = "0 0 8px rgb(255,255,255)";
                }
              }}
              onHoverEnd={(e) => {
                if (e.target) {
                  (e.target as HTMLElement).style.textShadow = "none";
                }
              }}
          >
            k. ⚡⚡
          </motion.h1>
          <nav>
            <ul className="flex space-x-6">
              {sections.map((section, index) => (
                  <motion.li
                      key={section.id}
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <a
                        href={`#${section.id}`}
                        className="text-sm uppercase tracking-wider hover:text-[#00FF85] transition-colors duration-300"
                    >
                      {section.title}
                    </a>
                  </motion.li>
              ))}
            </ul>
          </nav>
        </header>

        <main className="container mx-auto px-6 py-12 relative z-10">
          <motion.section
              className="mb-24 text-center"
              initial={{opacity: 0, y: 50}}
              animate={{opacity: 1, y: 0}}
              transition={{duration: 0.5}}
          >
            <motion.h2
                className="text-5xl md:text-7xl font-light mb-8 tracking-wide lowercase"
                style={{
                  background: "linear-gradient(45deg, #00CFFF, #00FF85, #9D00FF)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                initial={{opacity: 0, y: -20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
                whileHover={{
                  scale: 1.05,
                }}
                onHoverStart={(e) => {
                  if (e.target) {
                    (e.target as HTMLElement).style.textShadow = "0 0 8px rgb(255,255,255)";
                  }
                }}
                onHoverEnd={(e) => {
                  if (e.target) {
                    (e.target as HTMLElement).style.textShadow = "none";
                  }
                }}
            >
              kryptofabrik
            </motion.h2>
            <p className=" py-29 text-xl md:text-2xl mb-12 max-w-2xl mx-auto font-light">
              "krypto" = crypto related topic with a slavic touch<br/>
              "fabrik" = self explanatory<br/>
              <br/>
              two dumb guys having fun rawdogging the internetz<br/>
              crypto & web3 r&d agency <br/>
            </p>
          </motion.section>

          {sections.map((section, index) => (
              <motion.section
                  key={section.id}
                  id={section.id}
                  className="mb-24"
                  initial={{opacity: 0, y: 50}}
                  animate={{opacity: 1, y: 0}}
                  transition={{duration: 0.5, delay: index * 0.2}}
              >
                <h3
                    className="text-3xl font-light mb-6 inline-block tracking-wide"
                    style={{
                      color: index % 2 === 0 ? '#00CFFF' : '#00FF85',
                    }}
                >
                  {section.title}
                </h3>
                <p className="text-lg font-light leading-relaxed">
                {section.content}
                </p>
              </motion.section>
          ))}
        </main>

        <footer className="bg-[#1A1F2C] py-6 relative z-10">
          <div className="container mx-auto px-6 flex justify-between items-center">
            <p className="text-sm">&copy; 2024 Kryptofabrik. All rights reserved. This isn't financial advice and we highly recommend using your own brain - we're pretty dumb.</p>
            <div className="flex space-x-6">
              {[
                { name: 'GitHub', url: 'https://github.com/kryptofabrik' },
                { name: 'Twitter', url: 'https://twitter.com/kryptofabrik' },
                { name: 'Telegram', url: 'https://t.me/+IlUByMRkf-c0ODNk' },
                { name: 'Email', url: 'mailto:kryptofabrik@protonmail.com' },
              ].map((platform, index) => (
                  <motion.a
                      key={index}
                      href={platform.url}
                      className="text-sm uppercase tracking-wider text-white hover:text-[#00FF85] transition-colors duration-300"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                  >
                    {platform.name}
                  </motion.a>
              ))}
            </div>
          </div>
        </footer>
      </div>
  )
}